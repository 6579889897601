import { doc, getDoc } from 'firebase/firestore';
import { getDB } from '../firebase/firebaseDev';
console.log('getDB:', getDB);
export const getTransaction = async (id) => {
    const docRef = doc(getDB(), 'transactions', id); // create a reference to the document

    const docSnap = await getDoc(docRef); // fetch the document

    if (docSnap.exists()) {
        console.log('Document data:', docSnap.data());
        return docSnap.data();
    } else {
        console.log('No such document!');
        return null;
    }
};
