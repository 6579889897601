import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// Configuration for  main app
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
};

let mainApp = initializeApp(firebaseConfig, 'mainApp');
let auth = getAuth(mainApp);
let main_db = getFirestore(mainApp);

export const initialize = (instanceNumber) => {
    console.log('Initializing instance:', instanceNumber);
    // Configuration for the instance apps
    const firebaseConfigInstance = {
        apiKey: process.env[`REACT_APP_FIREBASE_API_KEY_Instance${instanceNumber}`],
        authDomain: process.env[`REACT_APP_FIREBASE_AUTHDOMAIN_Instance${instanceNumber}`],
        projectId: process.env[`REACT_APP_FIREBASE_PROJECT_ID_Instance${instanceNumber}`],
        storageBucket: process.env[`REACT_APP_FIREBASE_STORAGE_BUCKET_Instance${instanceNumber}`],
        messagingSenderId: process.env[`REACT_APP_FIREBASE_MESSAGING_SENDER_ID_Instance${instanceNumber}`],
        appId: process.env[`REACT_APP_FIREBASE_APP_ID_Instance${instanceNumber}`]
    };

    // Initialize the instance app
    const instanceApp = initializeApp(firebaseConfigInstance, `instanceApp${instanceNumber}`);
    console.log('Main app and instance app initialized:', mainApp, instanceApp);
};

export const getApp = () => mainApp;
export const getFirebaseAuth = () => auth;
export const getDB = () => main_db;
