import { useEffect, useState } from 'react';

import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { useTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { BrowserView, MobileView } from 'react-device-detect';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
export default function CheckoutForm(props) {
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const [clearPaymentElement, setClearPaymentElement] = useState(null);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [paymentSuccessSnackbarOpen, setPaymentSuccessSnackbarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const [message, setMessage] = useState(null);
    const [messageSnackbarOpen, setMessageSnackbarOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const theme = useTheme();

    useEffect(() => {
        if (!stripe) {
            console.log('No stripe');
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

        if (!clientSecret) {
            console.log('No client secret');
            return;
        }
        console.log(clientSecret);

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case 'succeeded':
                    setMessage('Payment succeeded!');
                    break;
                case 'processing':
                    setMessage('Your payment is processing.');
                    break;
                case 'requires_payment_method':
                    setMessage('Your payment was not successful, please try again.');
                    break;
                default:
                    setMessage('Something went wrong.');
                    break;
            }
        });
    }, [stripe]);
    const resetForm = () => {
        setErrorMessage('');
        if (clearPaymentElement) {
            clearPaymentElement();
        }
    };
    const handlePaymentElementReady = (element) => {
        setClearPaymentElement(() => () => element.clear());
    };
    const handleSnackbarClose = (event, reason) => {
        console.log('handleSnackbarClose called');
        if (reason === 'clickaway') {
            return;
        }

        setPaymentSuccessSnackbarOpen(false);
        setErrorSnackbarOpen(false);
        setMessageSnackbarOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            console.log('No stripe or elements');
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: `${process.env.REACT_APP_WEB_URL}/confirmation?transactionId=${props.transactionId}&instanceNumber=${props.instanceNumber}`
            }
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === 'card_error' || error.type === 'validation_error') {
            setMessage(error.message);
        } else {
            setMessage('An unexpected error occurred.');
        }

        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: {
            type: 'accordion',
            defaultCollapsed: true,
            radios: true,
            spacedAccordionItems: false
        },
        paymentMethodOrder: ['cashapp', 'apple_pay', 'google_pay', 'card']
    };

    return (
        <>
            <MobileView>
                <Box sx={{ pt: 3 }}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                            <Typography color={'white'} variant="h5" align="center" sx={{ paddingBottom: 1 }}>
                                Confirm Your Payment
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} width="100%">
                            <form onSubmit={handleSubmit}>
                                <PaymentElement onReady={handlePaymentElementReady} options={paymentElementOptions} />

                                <Box display="flex" justifyContent="center" mt={3}>
                                    <Grid item xs={12}>
                                        <Button
                                            data-testid="pay"
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            disabled={!stripe || !elements || loading}
                                            id="submit"
                                            size="large"
                                            fullWidth
                                            sx={{
                                                color: '#ffffff',
                                                backgroundColor: '#048304 ',

                                                fontWeight: 'bold',
                                                fontSize: {
                                                    xs: '0.7rem',
                                                    sm: '1rem',
                                                    md: '1.2rem',
                                                    lg: '1.4rem'
                                                },
                                                '&.Mui-disabled': {
                                                    color: '#ffffff',
                                                    backgroundColor: 'black',

                                                    fontSize: {
                                                        xs: '0.7rem',
                                                        sm: '1rem',
                                                        md: '1.2rem',
                                                        lg: '1.4rem'
                                                    }
                                                }
                                            }}
                                        >
                                            Confirm Payment ${props.paymentDetails.amount.toFixed(2)}
                                        </Button>
                                    </Grid>
                                    <Snackbar
                                        open={paymentSuccessSnackbarOpen}
                                        autoHideDuration={5000}
                                        onClose={handleSnackbarClose}
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                    >
                                        <MuiAlert onClose={handleSnackbarClose} severity="success" elevation={6} variant="filled">
                                            Payment completed successfully!
                                        </MuiAlert>
                                    </Snackbar>
                                    <Snackbar
                                        open={errorSnackbarOpen}
                                        autoHideDuration={5000}
                                        onClose={handleSnackbarClose}
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                    >
                                        <MuiAlert onClose={handleSnackbarClose} severity="error" elevation={6} variant="filled">
                                            {errorMessage}
                                        </MuiAlert>
                                    </Snackbar>
                                    <Snackbar
                                        open={messageSnackbarOpen}
                                        autoHideDuration={5000}
                                        onClose={handleSnackbarClose}
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                    >
                                        <MuiAlert onClose={handleSnackbarClose} severity="info" elevation={6} variant="filled">
                                            {message}
                                        </MuiAlert>
                                    </Snackbar>
                                </Box>
                                {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                            </form>
                            <Backdrop
                                open={loading}
                                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff', flexDirection: 'column' }}
                            >
                                <CircularProgress color="inherit" />
                                <Typography variant="h6" component="div" sx={{ marginTop: 2, color: '#048304' }}>
                                    Processing Payment
                                </Typography>
                            </Backdrop>
                        </Grid>
                    </Grid>
                </Box>
            </MobileView>
            <BrowserView>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', color: 'white' }}>
                    <div style={{ textAlign: 'center' }}>
                        <h1>Mobile Only</h1>
                        <p>We're sorry, but this application is only available on mobile devices.</p>
                        <p>Please visit us on your mobile device to access this app.</p>
                    </div>
                </div>
            </BrowserView>
        </>
    );
}
