import React, { useState, useEffect, useRef } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './checkoutForm.js';
import { Grid, CircularProgress, Typography, Container } from '@mui/material';
import { getTransaction } from './services/firestore.js';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { initialize } from './firebase/firebaseDev.js';
import './App.css';
// localhost:4200?transactionId=3vum52IYfBEKGq7O5D9z&instance=1
export default function App() {
    const [clientSecret, setClientSecret] = useState('');
    const [stripePromise, setStripePromise] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [transactionId, setTransactionId] = useState(null);
    const [transactionData, setTransactionData] = useState(null);
    const [instance, setInstance] = useState(null);

    const isUnmounted = useRef(false);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    useEffect(() => {
        isUnmounted.current = false;
        setLoading(true);

        const initializeAndFetchTransaction = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const transactionId = urlParams.get('transactionId');
            const instance = urlParams.get('instance');

            if (!transactionId || !instance) {
                setLoading(false);
                window.location.href = `${process.env.REACT_APP_WEB_URL}/confirmation?error=paymentFailed`;
                return;
            }

            try {
                initialize(instance);
                setTransactionId(transactionId);
                setInstance(instance);

                const data = await getTransaction(transactionId);
                setTransactionData(data);
                console.log('process.emv:', instance, process.env[`REACT_APP_FUNCTIONS_URL_Instance${instance}`]);
                const response = await fetch(`${process.env[`REACT_APP_FUNCTIONS_URL_Instance${instance}`]}/stripe-getStripeConfig`);
                if (!response.ok) {
                    throw new Error('An error occurred while creating the payment config. Please try again.');
                }
                const { publishableKey } = await response.json();
                setStripePromise(loadStripe(publishableKey));
            } catch (error) {
                console.error('Error initializing and fetching transaction:', error);
                if (!isUnmounted.current) {
                    let errorMessageFromServer = error.response?.data?.error;
                    setErrorMessage(errorMessageFromServer || error.message);
                    setErrorSnackbarOpen(true);
                    setTimeout(() => {
                        if (!isUnmounted.current) {
                            setLoading(false);
                            window.location.href = `${process.env.REACT_APP_WEB_URL}/confirmation?error=paymentFailed`;
                        }
                    }, 2500);
                }
            } finally {
                if (!isUnmounted.current) {
                    setLoading(false);
                }
            }
        };

        initializeAndFetchTransaction();

        return () => {
            isUnmounted.current = true;
        };
    }, []);

    useEffect(() => {
        isUnmounted.current = false;

        if (paymentSuccess) {
            console.log('Payment Success');
            return;
        }

        if (!transactionData || !instance) {
            return;
        }

        const createPaymentIntent = async () => {
            try {
                setLoading(true);
                console.log('trans', transactionData);
                console.log('Instance:', instance);
                console.log('Function URL:', process.env[`REACT_APP_FUNCTIONS_URL_Instance${instance}`]);
                const response = await axios.post(
                    `${process.env[`REACT_APP_FUNCTIONS_URL_Instance${instance}`]}/stripe-createPaymentIntent`,
                    {
                        amount: transactionData.amount,
                        currency: 'usd',
                        metadata: {
                            affiliate: transactionData.businessName,
                            teamId: transactionData.teamId,
                            clkk_id: transactionData.user.clkk_id,
                            instance: instance,
                            'customer-email': transactionData.user.email,
                            'customer-name': transactionData.user.firstName + ' ' + transactionData.user.lastName,
                            'entered-amount': transactionData.amount
                        }
                    }
                );
                console.log(response);
                setClientSecret(response.data.clientSecret);
            } catch (error) {
                console.error('Error creating payment:', error);
                if (!isUnmounted.current) {
                    setLoading(false);
                    let errorMessageFromServer = error.response?.data?.error;
                    setErrorMessage(errorMessageFromServer || 'An error occurred while creating the payment. Please try again.');
                    setErrorSnackbarOpen(true);
                    window.location.href = `${process.env.REACT_APP_WEB_URL}/confirmation?error=paymentFailed`;
                }
            } finally {
                if (!isUnmounted.current) {
                    setLoading(false);
                }
            }
        };
        createPaymentIntent();

        return () => {
            isUnmounted.current = true;
        };
    }, [paymentSuccess, transactionData, instance]);

    const appearance = {
        theme: 'night'
    };
    const options = {
        clientSecret,
        appearance
    };

    return (
        <div className="App">
            {loading ? (
                <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
                    <CircularProgress />
                    <Typography variant={isSmallScreen ? 'subtitle1' : 'h6'} align="center" color="textPrimary" marginTop={6}>
                        Loading payment details...
                    </Typography>
                </Grid>
            ) : (
                clientSecret && (
                    <Container maxWidth={isSmallScreen ? 'xs' : 'md'}>
                        {' '}
                        {/* Use Container to set maxWidth */}
                        <Elements options={options} stripe={stripePromise}>
                            <CheckoutForm
                                setPaymentSuccess={setPaymentSuccess}
                                paymentDetails={transactionData}
                                transactionId={transactionId}
                                instanceNumber={instance}
                            />
                        </Elements>
                    </Container>
                )
            )}
        </div>
    );
}
